import { BaseLayout } from '@components/Layout';
import { t, Trans } from '@lingui/macro';
import { BrokenLink, Button, colorFn, Link2, ReactFC, Text } from '@milo/react';
import { CellPhone } from '@milo/react/src/icons/CellPhone';
import { GetStaticProps } from 'next';
import { useRouter } from 'next/router';

const PageWrapper: ReactFC = (props) => {
  return <div className="flex h-full w-full items-center justify-center text-center" {...props} />;
};

const Content: ReactFC = (props) => {
  return <div className="relative flex h-[50vh] flex-col items-center justify-center space-y-4" {...props} />;
};

export const StandardFourOhFour = () => {
  return (
    <PageWrapper>
      <div className="mt-20 flex flex-col items-center gap-3">
        <BrokenLink className="mb-7 h-16 w-16" />
        <Text size="h3" className="font-bold">
          <Trans>C'est vide par ici...</Trans>
        </Text>
        <p className="mb-2">
          <Trans>Vous êtes tombé sur une page inexistante ou brisée.</Trans>
        </p>
        <Link2 href="/experiences">
          <Button>
            <Trans>Revenir aux expériences</Trans>
          </Button>
        </Link2>
      </div>
    </PageWrapper>
  );
};

export const MobileFourOhFour = () => {
  return (
    <PageWrapper>
      <Content>
        <CellPhone fill={colorFn('medium-dark')} className="mb-7 h-16 w-16" />
        <Text size="h3" className="font-bold">
          <Trans>Notre app se fait une peau neuve!</Trans>
        </Text>
        <Text>
          <Trans>En attendant, rendez vous sur notre site mtaregion.com</Trans>
        </Text>
        <Text>
          <Trans>
            La version connectée du site web, à partir de votre téléphone, vous permet de profiter des mêmes
            fonctionnalités que l’app. Assurez-vous que dans vos paramètres de navigation, la géolocalisation de votre
            emplacement est autorisée.
          </Trans>
        </Text>
      </Content>
    </PageWrapper>
  );
};

export default function _404() {
  const router = useRouter();
  const fromMobileApp = router?.asPath.includes('mobileToken');

  if (fromMobileApp) {
    return (
      <BaseLayout showNavigation={false} showFooter={false}>
        <MobileFourOhFour />;
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <StandardFourOhFour />
    </BaseLayout>
  );
}

export const getStaticProps: GetStaticProps = async () => {
  return {
    props: {
      seo: {
        title: t`Page non trouvée`,
        description: t`Page non trouvée`,
      },
    },
  };
};
